.portofolio {
  .ant-modal-body {
    min-height: 75vh;
    overflow-y: auto;
    padding-block: 3rem;
    position: relative;
  }
  &__spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%);
  }

  .my-masonry-grid {
    margin-inline: 0;
    overflow-y: auto;
    height: 65vh;
  }

  .project {
    position: relative;
    margin-bottom: 1.5rem;
    &__fig {
      box-shadow: 0px 3px 6px #00000029;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
    }
    &__subtitle {
      font-size: 0.875rem;
      color: #3d3d3d;
      margin-bottom: 0.5rem;
    }
    &__title {
      font-size: 1.5rem;
      font-weight: 900;
      color: #3d3d3d;
    }
    .approval {
      position: absolute;
      top: 0.25rem;
      right: 0.25rem;
      z-index: 9;

      &-sign {
        content: "";
        background-color: rgb(38, 173, 95);
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      &-text {
        color: #ed462f;
        font-size: 14px;
        font-weight: bold;
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        padding: 4px;
        border-radius: 2px;
      }
    }
  }
}

.single-project {
  .ant-modal-body {
    min-height: 75vh;
  }
  .my-masonry-grid {
    margin-inline: 0;
    overflow-y: auto;
    height: 40vh;
  }
  .my-masonry-grid_column {
    padding-inline-end: 1rem;
    padding-inline-start: 0;
  }

  &__header {
    .item {
      margin-bottom: 1rem;
      color: #333;
      display: flex;
      align-items: baseline;
      .title {
        color: $main-color;
        margin-inline-end: 0.5rem;
        font-size: 1rem;
        font-weight: bold;
        width: 115px;
      }
    }
  }
  &__image {
    figure {
      position: relative;
      img {
        width: 100%;
      }

      .checkbox {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        border-color: $main-color;
      }
    }
  }
  &__text-area {
    textarea {
      width: 100%;
      min-height: 4rem;
      font-size: 0.875rem;
      resize: none;
      padding: 0.5rem;
    }
    margin-block: 1.5rem;
  }
  &__btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
