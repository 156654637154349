.home_services {
	.btn-success,
	.btn-success:focus {
		color: #127e99;
		border: 1px solid #127e99;
		background: #fff;
	}
	.ant-form-item-no-colon {
		display: flex;
		justify-content: start;
		/* font-size: 18px; */
		font-weight: bold;
	}

	.dropdown-menu {
		min-width: 0;
		width: fit-content;
		max-height: 100px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 2px;
			margin: 0 10px;
		}

		/* Track */
		&::-webkit-scrollbar-track {
			background: #fff;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #127e99;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
			background: #555;
		}
	}
}
