#app {
  --orange-primary: #f58234;
  --orange-secondary: #cc5300;
  --blue-primary: #356ab3;
  --blue-secondary: #27a2d9;
  --blue-thirdy: #0370ff;
  --white: #ffffff;
  font-family: "Cairo", sans-serif;
  font-family: "Work Sans", sans-serif;
  font-family: "Roboto", sans-serif;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  scroll-behavior: smooth;
}
