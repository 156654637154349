.add-new-modal {
	.title {
		color: $black-border;
	}
	.ant-modal-content {
		border-radius: 5px;
	}
	.rounded-input {
		box-shadow: 0 3px 6px #897f7f;
		border-radius: 15px;
		height: 44px;
	}
}

.blogs-page {
	padding: 1.5rem;
	margin: 1.5rem;
	box-shadow: $box-shadow;
	.article-title:hover {
		text-decoration: underline;
		cursor: pointer;
	}
	.primary-dropdown,
	.primary-dropdown:hover,
	.primary-dropdown:focus {
		background: none;
		color: $main-color;
	}
	.articles-section {
		padding: 0.5rem;
		box-shadow: $box-shadow;
		color: $black-border;
		.article-row {
			border-bottom: 1px solid #beb9b9;
			padding-bottom: 5px;
		}
	}
	.blog-article-details {
		padding: 0.5rem;
		box-shadow: $box-shadow;
		color: $black-border;
		margin: 0 0.5rem;
		.article-text {
			resize: none;
			height: 434px;
		}
		.image-detail-figure {
			width: 95%;
			margin: auto;
			position: relative;
			.image-detail {
				width: 100%;
			}
			.cross-icon {
				position: absolute;
				top: 5px;
				right: 5px;
				cursor: pointer;
			}
		}
		@media (max-width: 768px) {
			.main-actions {
				flex-direction: column;
				align-items: flex-start !important;
			}
		}
	}

	.blog-banner {
		.divider {
			content: "";
			border-bottom: 1px solid #2693ad;
		}
	}
	.upload-button {
		border: 2px solid $main-color !important;
	}
	.bap-input {
		border-color: #707070;
		&::placeholder {
			color: #897f7f;
		}
	}
	.blog-article {
		.tabs-margin {
			margin: 0 80px;
		}
		.ant-tabs-tab-active {
			text-decoration: none;
		}
		.ant-tabs-nav-more {
			display: none;
		}
	}
}
