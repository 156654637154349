.privacy {
  &__container {
    padding: 1rem;
  }

  &__textarea {
    height: 500px;
    margin-bottom: 1.5rem;
  }

  &__btn-container {
    text-align: center;
    button {
      width: 131px;
      height: 2.5rem;
    }
  }
}
