.custom-modal {
  .ant-modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: $box-shadow;
  }

  &__text {
    flex: 1;
    width: 100%;
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: $main-color;
    font-weight: 600;
  }

  &__controllers {
    button {
      color: white;
      border: none;
      outline: none;
      background: transparent;
      margin-inline-end: 2rem;
      width: 144px;
      height: 48px;
      font-size: 1rem;
      font-weight: 600;

      &:last-of-type {
        margin-inline-end: 0;
      }

      &.secondary-button {
        background: #707070;
        color: white;
      }
      &.danger-button {
        border: 3px solid #ff0505;
        color: #ff0505;
      }
      &.primary-button {
        border: 3px solid $main-color;
        color: $main-color;
      }
    }
  }
}

.projects-modal {
  &__ul {
    height: 250px;
    overflow-y: auto;
    margin-top: 1rem;
    width: 100%;
    box-shadow: $box-shadow;

    li {
      color: #707070;
      margin-bottom: 1rem;
      font-size: 1rem;
      text-align: center;

      &:first-of-type {
        margin-block-start: 2rem;
      }
    }
  }
}
