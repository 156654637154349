/* -------------------------------------------------------------------------- */
/*                                   global                                   */
/* -------------------------------------------------------------------------- */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

ul,
li {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Remove Arrows from numeric input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* -------------------------------------------------------------------------- */
/*                                   customization                            */
/* -------------------------------------------------------------------------- */

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th {
  border-color: #897f7f !important;
  &:last-of-type {
    border-color: transparent;
    border-bottom-color: #897f7f !important;
  }
}

.ant-table-thead > tr > th {
  background: white !important;
}

.ant-table-row {
  .ant-table-cell {
    color: $main-color;
    font-size: 13px;
  }
  &:first-of-type {
    .ant-table-cell {
      color: #897f7f;
      background-color: white;
    }
  }
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table {
  border-top: 0;
}

.ant-table.ant-table-bordered > .ant-table-container {
  border-left: 0px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #897f7f !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 1;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

/* -------------------------------------------------------------------------- */
/*                                   Classes                                  */
/* -------------------------------------------------------------------------- */

header.main-header {
  h2 {
    color: $main-color;
    font-size: 1.5rem;
    font-weight: bold;
    margin-block: 0.625rem 0.875rem;
    margin-inline-start: 2.9375rem;
  }
  .main-title {
    margin: 16px;
  }
}

.box-shadow {
  box-shadow: $box-shadow;
}

.danger-color {
  color: $danger-color;
}

.main-color {
  color: $main-color;
}
.warn-color {
  color: $warn-color;
}

.underline {
  text-decoration: underline;
}

/* -------------------------------------------------------------------------- */
/*                                   Components                               */
/* -------------------------------------------------------------------------- */
.bap-image-show {
  border: 1px solid $black-border;
  padding: 1rem 0.5rem;
  height: 115px;
}

.bap-outline-button {
  border: 2px solid $main-color;
  height: 35px;
  width: 194px;
  color: $main-color;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-inline-start: 0.25rem;
  }

  &:focus,
  &:hover {
    color: $main-color;
    border-color: $main-color;
  }
}

.bap-input,
.bap-textarea {
  display: block;
  border-radius: 0;
  border: 1px solid $black-border;
  width: 100%;
  padding: 0.375rem;
  font-size: 1rem;
}
.bap-textarea {
  resize: none;
  min-height: 125px;
}
.bap-button {
  width: 9rem;
  height: 2.25rem;
}
.bap-primary-button {
  .disabled {
    background: none !important;
  }
}
.controllings-Buttons {
  display: flex;
  justify-content: flex-end;
  button {
    margin-inline-end: 1rem;
    &:last-of-type {
      margin-inline-end: 0;
    }
  }
}
.bap-primary-button {
  color: white;
  background: $main-color !important;
  box-shadow: $box-shadow;
  font-size: 1rem;
  &:hover,
  &:focus {
    border-color: $main-color;
    color: $main-color;
    background-color: white !important;
  }
  // button
}
.ant-btn-primary[disabled] {
  color: white !important;
  opacity: 0.5;
  &:hover,
  &:focus {
    background: $main-color !important;
    opacity: 0.5;
  }
  color: white;
  background: $main-color;
  box-shadow: $box-shadow;
  font-size: 1rem;
  &:hover,
  &:focus {
    border-color: $main-color;
    color: $main-color;
  }
}
.text-underline {
  text-decoration: underline;
}

.error-text {
  color: rgb(255, 33, 33);
  font-size: 0.875rem;
  font-weight: bold;
}
.flex-1 {
  flex: 1 !important;
}
// Font Sizes
.f-15 {
  font-size: 15px;
}
.f-23 {
  font-size: 23px;
}
.f-21 {
  font-size: 21px;
}
//End Font Sizes

/*********************************************/

//Font weights
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
//End Font weights
/*********************************************/
/* -------------------------------------------------------------------------- */
/*                                   masonry grid                             */
/* -------------------------------------------------------------------------- */

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  margin-inline-start: 1rem;
  margin-inline-end: 1rem;
  width: auto;
}
.my-masonry-grid_column {
  padding-inline-start: 1.2rem;
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  margin-bottom: 1rem;
}

/* -------------------------------------------------------------------------- */
/*                                  Loading Spinner                             */
/* -------------------------------------------------------------------------- */

.main-section__container {
  position: relative;
  .spinner__container {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($main-color, 0.15);
    z-index: 999;
    margin-inline-end: 2rem;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
