.not-found {
  background-image: url("../Images/Background\ [STYLESTYLE2].png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .not-found__container {
    height: 100vh;
    min-height: 450px;
    img {
      max-width: 100%;
    }
    .text-container {
      font: normal normal 600 40px/49px Montserrat;
      color: #090909;
    }
    .not-found__back {
      color: #cfcfcf;
      background-color: #12819c;
      text-shadow: 0px 3px 6px #00000029;
      padding: 13px 40px;
      font: normal normal 600 16px/19px Montserrat;
    }
  }
}

@include to-tablet-sm {
  .text-container {
    font: normal normal 600 25px/30px Montserrat !important;
  }
}
