.signin {
  background: url("../../assets/Images/bg-3.svg") no-repeat left center;
  background-size: cover;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &__modal {
    width: 639px;
    padding: 3.375rem 3.0625rem;
    box-shadow: $main-shadow;
    background: white;

    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label,
    .ant-form-vertical .ant-form-item-label {
      padding-bottom: 0;
      line-height: 1;
    }
    .ant-form-item-label > label {
      color: $main-color;
      font-size: 1rem;
      margin-bottom: 7px;
    }

    .ant-form-item-label
      > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    .input-password.ant-input-affix-wrapper {
      border-width: 0px !important;
      border-bottom: 1px solid $main-color !important;
      border-radius: 0;

      .ant-input {
        border-bottom-width: 0 !important;
      }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      box-shadow: 0 0 0 2px transparent !important;
    }

    .ant-input {
      border-width: 0px !important;
      border-bottom: 1px solid $main-color !important;
      border-radius: 0;
    }

    .ant-input-focused,
    .ant-input:focus {
      box-shadow: 0 0 0 2px transparent !important;
    }
    position: relative;
  }

  header {
    h2 {
      font-size: 1.875rem;
      color: $main-color;
      letter-spacing: 0.24px;
      margin-bottom: 0;
    }
  }
  .close-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__logo {
    margin-bottom: 1.25rem;
    width: 12.25rem;
    img {
      width: 100%;
    }
  }

  &__form {
  }

  &__inputs {
    margin-top: 1.8125rem;
  }

  &__keep {
    margin-top: 16px;
    .ant-checkbox-checked .ant-checkbox-inner {
      background: $main-color;
      border: 1px solid $main-color;
      border-radius: 0.25rem;
    }
    span {
      font-size: 0.875rem;
      color: $main-color;
    }
  }

  &__submit {
    text-align: center;
    margin-top: 2.5rem;
    margin-bottom: 0;
    .ant-btn-primary {
      background-color: $main-color;
      border-color: $main-color;
      height: 48px;
      width: 9rem;
    }
  }
}
