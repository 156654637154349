.pages {
	padding: 20px;
	label {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&.landing {
		background: #fbfbfb;
		.ant-collapse {
			border: none;
			padding-left: 0;
			padding-right: 0;
			.ant-collapse-item {
				border-color: $main-color-2 !important;
				border-width: 1px;
			}
			&.ant-collapse-item-active {
				border: none;
			}
		}

		.accordionTitle {
			color: $main-color-2;
			font-weight: 600;
			font-size: 15px;
		}
		.banner,
		.utlsAndMore,
		.home_services,
		.howItWorks,
		.qAndA {
			padding: 1.5rem;
			box-shadow: $box-shadow;
			& > * {
				margin-bottom: 1rem;
			}
			header h6 {
				margin-bottom: 0;
				color: $main-color;
				font-size: 1rem;
			}
		}
	}
}
