.filters {
  &__wrapper {
    margin-inline-start: 1rem;
    max-width: 160px;
  }
  &__assign {
    margin-inline-end: 1rem;
    font-size: 0.75em;
    color: #707070;
  }
  &__submit {
    margin-inline-end: 1rem;
  }
}

.grant-modal {
  .grant__container {
    display: flex;
    align-items: center;
    justify-content: center;
    figure {
      background-color: #f3f3f3;
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-of-type {
        margin-inline-end: 3rem;
      }
      &:last-of-type {
        margin-inline-start: 3rem;
      }
    }
    .add-sub {
      width: 50%;
    }
  }
  .grant {
    &__tokens {
      display: flex;
      align-items: center;
      justify-content: center;
      .tokens {
        font-size: 1.5rem;
        color: #333;
        margin-inline-end: 1rem;
      }
    }
  }
}
