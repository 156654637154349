.search-table {
  margin-bottom: 1rem;
  &__form {
    position: relative;
  }
  &__input {
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #cac9c9;
    padding-left: 1.5rem;
    padding-block: 0.25rem;
    font-size: 1rem;
    color: #808080;
    &:focus {
      outline: none;
      border-color: #808080;
    }
  }
  &__img {
    position: absolute;
    bottom: 0;
    left: 1px;
    margin-bottom: 0.5rem;
  }
}
