.navbar {
  padding-block: 23px;
  box-shadow: $box-shadow;
  &__start {
    img {
    }
  }
  &__end {
    //   border: 1px solid red;
    //   flex: 1;
    //   display: flex;
    //   justify-content: flex-end;
  }
  &__ul {
    display: flex;
    align-items: center;
    .item {
      margin-inline-start: 1.8125rem;
      img {
        width: 1.25rem;
        height: 1.25rem;
      }
      span {
        color: $main-color;
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.021;
        &:first-of-type {
          font-size: 0.8125rem;
          font-weight: normal;
        }
      }
    }
  }
}
