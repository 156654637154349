.feedback {
  &__modal {
    .ant-modal-body {
      padding: 0;
    }
    .anticon-close.ant-modal-close-icon svg {
      fill: white;
    }
  }

  &__container {
    background: white;
    padding: 1rem;
    margin-block-end: 2rem;
  }

  &__header {
    height: 65px;
    background: $main-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      font-size: 22px;
      color: white;
      text-align: center;
      margin-bottom: 0.25rem;
      font-weight: 600;
    }
    div {
      color: #f1f1f1;
      font-weight: 600;
      font-size: 14px;
      position: relative;
      // border-top: 1px solid white;
    }
  }

  h5 {
    font-size: 17px;
    font-weight: bold;
    color: #707070;
    margin-block: 24px;
  }

  &__questions {
    min-height: 400px;
    height: 400px;
    overflow-y: auto;
    position: relative;
    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
    }
    .question {
      margin-bottom: 2rem;

      &__header {
        font-size: 1rem;
        font-weight: bold;
        color: #707070;
        margin-bottom: 1.15rem;
        display: flex;
        align-items: center;
        span {
          width: 5px;
          height: 5px;
          border: 0.25rem solid $main-color;
          border-radius: 50%;
          padding: 0.1rem;
          display: block;
          margin-inline-end: 0.625rem;
        }
        &.quesiton-header-sm {
          font-weight: normal;
          font-size: 1rem;
        }
      }

      &__answers {
        .answer {
          &__wrapper {
            margin-bottom: 20px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .ant-radio-wrapper {
              display: block;
              color: #707070;
              font-size: 1rem;
              span {
                // margin-inline-end: 0.7rem;
                font-size: 15px;
                font-weight: 400;
              }
            }
          }
        }
      }
      &__yes-no {
        margin-block: 1.25rem;
      }
      &__input {
        min-height: 230px;
        width: 99%;
        resize: none;
      }
      &__rating {
        display: flex;
        align-items: center;

        .text {
          margin-inline-start: 2rem;
          margin-inline-end: 2rem;
          font-size: 0.9375rem;
          font-weight: 600;
          width: 142px;
          color: #707070;
          &:last-of-type {
            margin-inline-end: 0;
          }
        }
        ul.rates {
          display: flex;
          align-items: center;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 8px;
            position: absolute;
            top: 50%;
            transform: translatey(-50%);
            left: 0;
            background-color: rgba($main-color, 0.1);
            z-index: 1;
          }

          .rate {
            width: 40px;
            height: 40px;
            border: 1px solid $main-color;
            background: white;
            color: $main-color;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: static;
            z-index: 2;
            margin-inline-end: 25px;
            cursor: pointer;
            &:last-of-type {
              margin-inline-end: 0rem;
            }
            &.active {
              background: $main-color;
              color: white;
              transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            &.disabled {
              color: rgba($main-color, 0.2);
              border-color: rgba($main-color, 0.2);
            }
          }
        }
      }
    }
  }
}
