.tokens {
  &__bidding {
    padding-inline: 2rem;
    padding-block: 2rem;
  }
  &__areas {
    margin-top: 2rem;
    position: relative;
    padding-top: 2rem;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 75%;
      height: 2px;
      background-color: #cacaca;
    }
  }
  &__area {
    font-size: 1rem;
    color: #707070;
  }
  &__head {
    font-size: 1rem;
    color: #707070;
    margin-bottom: 1.5rem;
  }
  &__title {
    font-size: 1.25rem;
    color: $main-color;
  }
  &__button-wrapper {
    text-align: center;
    margin-block-start: 1.5rem;
    .button {
      background: $main-color;
      color: white;
    }
  }
  .error-text {
    text-align: center;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    box-shadow: 0 0 0 2px transparent !important;
  }

  .ant-input {
    border-width: 0px !important;
    border-bottom: 1.2px solid #707070 !important;
    font-size: 1.25rem;
    color: #707070;
    border-radius: 0;
    width: 75px;
    text-align: center;
  }

  .ant-input-focused,
  .ant-input:focus {
    box-shadow: 0 0 0 2px transparent !important;
  }
}
