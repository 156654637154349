.sidebar {
  width: 100%;
  background-color: $main-color;
  flex: 1;

  .accordion-item {
    border-radius: 0 !important;
    border: none;
    background-color: #5fb4c9;
    color: white;
    border-bottom: 1px solid white;

    &:first-of-type .accordion-button {
      border-radius: 0 !important;
    }

    &:last-of-type .accordion-button {
      border-radius: 0 !important;
    }
  }
  .accordion-button {
    background: $main-color;
    color: white;
    img {
      margin-inline-end: 1rem;
    }

    &::after {
      transform: rotate(0deg);
      background-image: url("../Icons/chevron.svg");
    }

    &:not(.collapsed)::after {
      transform: rotate(90deg);
      background-color: greeen;
    }

    &:focus {
      box-shadow: 0 0 0 0;
    }
  }
  .accordion-collapse {
    padding-inline: 1rem;
  }

  &__child {
    display: block;
    background-color: white;
    color: $main-color;
    width: 100%;
    box-shadow: $box-shadow;
    padding-block: 0.5rem;
    text-align: center;
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:hover,
    &:focus {
      color: $main-color;
      text-decoration: underline;
    }
  }
}
